<template>
  <div id="card" class="container">
    <div class="d-flex">
      <h4 style="text-transform: uppercase" class="card-title mr-auto m-0">{{ name }}</h4>
      <span class="arrow-down-icon" :class="{ arrowup: isToggled }" @click="toggle()">
        <img src="../assets/svgs/arrow-up.svg" />
      </span>
    </div>
    <div class="list-area" v-if="!isToggled">
      <div class="card-table">
        <div class="card-row" v-for="(item, index) in items" :key="index + 'item'">
          <div class="table-item">
            <div class="first-field" v-html="`<strong>${item.title}</strong>-${item.text}`"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ReportCard',
  props: {
    items: { type: Array },
    name: { type: String },
  },
  data() {
    return {
      isToggled: false,
    }
  },
  methods: {
    toggle() {
      this.isToggled = !this.isToggled
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchProfile.scss';

.first-field {
  width: 100% !important;
  padding-right: 40px;
}
.card-row {
  height: auto !important;
  padding-bottom: 20px;
}
</style>
