<template>
  <div id="reportheader">
    <b-row class="report-date text-center text-color-black" v-if="displayType == 'daily'">
      <b-col sm="4">
        <span class="prenext prev text-uppercase" v-if="ifPrev" @click="previousDate()">
          {{ i18n['report-header'].tcPrevious }}
        </span>
        <b-button class="btn-no-content" @click="previousDate()">
          <b-icon icon="chevron-left" v-if="ifPrev" shift-h="5" aria-hidden="true"></b-icon>
        </b-button>
      </b-col>
      <b-col sm="4">
        <h4 class="campdate" v-if="currentDisplayDate">{{ setDay }}</h4>
      </b-col>
      <b-col sm="4">
        <b-button class="btn-no-content" @click="nextDate()">
          <b-icon icon="chevron-right" v-if="ifNext" aria-hidden="true"></b-icon>
        </b-button>
        <span class="prenext text-uppercase" v-if="ifNext" @click="nextDate()">{{ i18n['report-header'].tcNext }}</span>
      </b-col>
    </b-row>
    <br />
    <div align-v="center" class="font-weight-bold">
      <b-row>
        <b-col sm="8">
          <div class="view-filter pl-3 btn-group-md">
            <b-button
              variant="primary"
              :class="[selectedLevel == 'all' ? 'btn-secondary-toggle-selected' : 'btn-secondary-toggle-unselected']"
              @click="selectFilter('all')"
              >{{ i18n['report-header'].tcAll }}</b-button
            >
            <b-button
              variant="primary"
              :class="[selectedLevel == 'camp' ? 'btn-secondary-toggle-selected' : 'btn-secondary-toggle-unselected']"
              @click="selectFilter('camp')"
              >{{ i18n['report-header'].tcCamp }}</b-button
            >
            <b-button
              v-if="isUS"
              variant="primary"
              :class="[selectedLevel == 'state' ? 'btn-secondary-toggle-selected' : 'btn-secondary-toggle-unselected']"
              @click="selectFilter('state')"
              >{{ i18n['report-header'].tcState }}</b-button
            >
            <b-button
              v-if="!isUS"
              variant="primary"
              :class="[
                selectedLevel == 'country' ? 'btn-secondary-toggle-selected' : 'btn-secondary-toggle-unselected',
              ]"
              @click="selectFilter('country')"
              >{{ i18n['report-header'].tcCountry }}</b-button
            >
            <b-button
              variant="primary"
              :class="[
                selectedLevel == 'headquarters' ? 'btn-secondary-toggle-selected' : 'btn-secondary-toggle-unselected',
              ]"
              @click="selectFilter('headquarters')"
              >{{ i18n['report-header'].tcInternational }}</b-button
            >
          </div>
        </b-col>
        <b-col sm="4">
          <b-button-group class="report-switch">
            <b-button
              variant="primary"
              :class="[displayType == 'daily' ? 'primay' : 'btn-toggle-unselected']"
              @click="redirectToMonthly()"
              >{{ i18n['report-header'].tcDaily }}</b-button
            >
            <b-button
              variant="primary"
              :class="[displayType == 'monthly' ? 'primary' : 'btn-toggle-unselected']"
              @click="redirectToDaily()"
              >{{ i18n['report-header'].tcMonthly }}</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
    </div>
    <br />
    <div class="bottom-button-group">
      <div class="daily-button-group">
        <b-button
          class="btn-gray m-2 sm-text text-uppercase"
          v-if="displayType == 'daily'"
          @click.prevent="downloadReport(reportDownloadUrl, 'DailyPrayerCalendar', 'pdf')"
        >
          {{ i18n['report-header'].tcDailyCalendarPDF }}
        </b-button>
        <b-button
          class="btn-gray m-2 sm-text text-uppercase"
          v-if="displayType == 'daily'"
          @click.prevent="downloadReport(reportDownloadUrlExcel, 'DailyPrayerCalendar', 'csv')"
        >
          {{ i18n['report-header'].tcDailyCalendarEXCEL }}
        </b-button>
        <b-button v-if="showFullCalendarDownload" class="btn-gray m-2 sm-text text-uppercase">
          {{ i18n['report-header'].tcPrintFullCalendars }}
        </b-button>
      </div>
      <b-col class="manage-button-group">
        <b-button
          v-if="iCanSeeAny(secured_prayer_management_controls)"
          variant="primary"
          class="m-2 sm-text float-right text-uppercase"
          @click="openManageCalendar()"
          >{{ i18n['report-header'].tcManageCalendars }}</b-button
        >
        <b-button
          v-if="iCanSeeAny(secured_prayer_request_management_controls)"
          variant="primary"
          class="m-2 sm-text float-right text-uppercase"
          @click="openManagePrayerRequest()"
          >{{ i18n['report-header'].tcManageRequests }}</b-button
        >
      </b-col>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { REPORT_CONFIG } from '../config/report.config'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'report-header',
  mixins: [reportDownloadMixin],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'report-header': {
            tcAt: 'at',
            tcContactedBy: 'Contacted By',
            tcCoordinator: 'Coordinator',
            tcMedia: 'Media',
            tcMore: 'More',
            tcNotes: 'Notes',
            tcOffering: 'Offering',
            tcQualified: 'Qualified',
            tcSpeaker: 'Speaker',
            tcStartsAt: 'Starts at',
          },
        }
      },
    },
    currentDisplayDate: {},
    displayType: {},
  },

  data() {
    return {
      selectedLevel: 'all',
      showFullCalendarDownload: false,
      fields: [
        { key: 'Church Name', sortable: true },
        { key: 'City', sortable: true },
        { key: 'Denomination', sortable: true },
        { key: 'GideonRep', sortable: true },
        { key: 'Camp Assigned', sortable: true },
        { key: 'Last Presentation', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: true,
        text: 'Add Church',
        action: 'addPresenter',
      },
      secured_prayer_management_controls: {
        camp_prayer_calendar_manage_panel: 'edf13799-d1b8-4cea-9f40-4d13c463ff97',
        country_prayer_calendar_manage_panel: '3998e64e-43b9-480f-ba15-877708bd33ae',
        hq_prayer_calendar_manage_panel: 'cbc03716-e57d-4e87-879c-71a84935b3a2',
        state_prayer_calendar_manage_panel: 'db8a4765-414e-4d14-b78a-2752455cbacf',
      },
      secured_prayer_request_management_controls: {
        camp_prayer_calendar_manage_request_panel: 'b02e7c3a-b2cc-4ba1-a821-5c091eb83b0e',
        country_prayer_calendar_manage_request_panel: 'cf9cb55c-87aa-4b33-8b41-8205b2a94236',
        hq_prayer_calendar_manage_request_panel: '1e6ede90-c9d0-48e0-9ef9-75a5c0f21836',
        state_prayer_calendar_manage_request_panel: '20f3c818-cc4e-4540-884c-7e9082439e87',
      },
      translations: {},
    }
  },
  methods: {
    ...mapActions([{ setLoadingStatus: 'menu/setLoadingStatus' }]),
    addPresenter() {
      this.$router.push('/')
    },
    selectFilter(level) {
      this.selectedLevel = level
      this.$emit('filterBy', this.selectedLevel)
    },
    openManageCalendar() {
      this.$router.push({ name: 'prayer-add-edit-calendar' })
    },
    openManagePrayerRequest() {
      this.$emit('addEditPrayerRequest')
    },
    redirectToDaily() {
      this.$router.push({ name: 'prayer-center-monthly' })
    },
    redirectToMonthly() {
      this.$router.push({ name: 'prayer-center-daily' })
    },
    previousDate() {
      this.$emit('setPrevDate')
    },
    nextDate() {
      this.$emit('setNextDate')
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userCampKey: 'user/userCampKey',
      userStateKey: 'user/userStateKey',
      userCountryKey: 'user/userCountryKey',
    }),
    setDay() {
      let monthName = this.currentDisplayDate.toLocaleString(this.prefCulture, { month: 'short' })
      return `${monthName} ${this.currentDisplayDate.getDate()}`
    },
    ifPrev() {
      let prevDate = new Date()
      let currentMonth = this.currentDisplayDate.getMonth()
      prevDate.setDate(this.currentDisplayDate.getDate() - 1)
      return prevDate.getMonth() === currentMonth
    },
    ifNext() {
      let nextDate = new Date()
      let currentMonth = this.currentDisplayDate.getMonth()
      nextDate.setDate(this.currentDisplayDate.getDate() + 1)
      return nextDate.getMonth() === currentMonth
    },
    reportDownloadUrl() {
      return REPORT_CONFIG.getDailyPrayerCalendar(
        this.userCampKey + '|' + this.userStateKey + '|' + constantData.international_key,
        this.currentDisplayDate.getFullYear(),
        this.currentDisplayDate.getDate(),
        this.prefCulture.substr(0, 2),
        'camp|state|headquarters',
        'pdf'
      )
    },
    reportDownloadUrlExcel() {
      return REPORT_CONFIG.getDailyPrayerCalendar(
        this.userCampKey + '|' + this.userStateKey + '|' + constantData.international_key,
        this.currentDisplayDate.getFullYear(),
        this.currentDisplayDate.getDate(),
        this.prefCulture.substr(0, 2),
        'camp|state|headquarters',
        'excel'
      )
    },
    isUS() {
      return this.userCountryKey === constantData.USA_country
    },
  },
  components: {},
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/components/component-reportheader.scss';
@import '@/styles/views/ReportCenter.scss';
</style>
