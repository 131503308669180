<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPrayerCenter }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <div class="page-body container mx-lg mt-4">
        <reportHeader
          @filterBy="applyFilter($event)"
          @setPrevDate="previousDate()"
          @setNextDate="nextDate()"
          @addEditPrayerRequest="addEditPrayerRequest()"
          :displayType="displayType"
          :currentDisplayDate="showingDate"
          :i18n="translations.components"
        ></reportHeader>
        <div class="report-cards">
          <reportCard
            v-for="(prayer, index) in prayers"
            :key="index"
            :obj="prayer"
            :items="prayer.request"
            :name="prayer.title"
          />
        </div>
      </div>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import ReportCard from '@/components/ReportCard'
import ReportHeader from '@/components/ReportHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { stateBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-center-daily',
  mixins: [stateBreadcrumbMixin, translationMixin],
  data: () => ({
    showingDate: 0,
    displayType: 'daily',
    currentFilter: 'all',
    prayers: [],
    translations: {},
  }),
  methods: {
    ...mapActions({
      getDailyPrayer: 'prayerCenter/getDailyData',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    officer_toolbar_in_use() {
      return this.user_camp_key !== this.demograph.camp_key
    },
    extrapolateData(data, group) {
      let setData = data
      this.prayers = []
      if (group !== 'all') {
        setData = setData.filter((data) => data.group === group)
      }
      setData.map((group) => {
        let preTitle = group.group
        this.setCategories(group.daily.categories, preTitle, 'daily')
        this.setCategories(group.special.categories, preTitle, 'special')
      })
    },
    setCategories(categories, preTitle, type) {
      categories.map((category) => {
        let title = ''
        let preTitleTranslated = this.translations[this.convertValForTranslation(preTitle)]
        preTitleTranslated = preTitleTranslated ? preTitleTranslated : preTitle
        if (type === 'special') {
          let typeSpecial = this.translations.tcSpecial
          title = preTitleTranslated === typeSpecial ? preTitleTranslated : `${preTitleTranslated} ${typeSpecial}`
        } else {
          let categoryTranslated = this.translations[this.convertValForTranslation(category.category)]
          categoryTranslated = categoryTranslated ? categoryTranslated : category.category // if no match, return category text
          title =
            preTitleTranslated === categoryTranslated
              ? preTitleTranslated
              : `${preTitleTranslated} ${categoryTranslated}`
        }
        let request = category.items.map((item) => {
          return { title: item.title, text: item.request }
        })
        let obj = {
          title: title,
          request: request,
        }
        this.prayers.push(obj)
      })
    },
    applyFilter($event) {
      this.extrapolateData(this.dailyPrayers, $event)
      this.currentFilter = $event
    },
    addEditPrayerRequest() {
      this.$router.push({ name: 'prayer-add-edit-request' })
    },
    async nextDate() {
      this.nextPrevious(1)
    },
    async previousDate() {
      this.nextPrevious(-1)
    },
    async nextPrevious(amt) {
      let newDate = new Date()
      newDate.setDate(this.showingDate.getDate() + amt)
      this.showingDate = newDate
      await this.page_load()
    },
    async page_load(evt) {
      try {
        if (!evt) {
          // execute only if evt is undefined
          await Promise.all([this.getDailyPrayer(this.showingDate.getDate())]).then(() => {
            this.extrapolateData(this.dailyPrayers, this.currentFilter)
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.showingDate = new Date()
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('camp-select', 'report-header', 'security-banner'),
        await this.getComponentTranslations('common.months-abbreviations'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.page_load()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      dailyPrayers: 'prayerCenter/dailyData',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      user_camp_key: 'user/userCampKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path.replace('/aux', ''))
    },
  },
  components: {
    campSelector: CampSelect,
    reportCard: ReportCard,
    reportHeader: ReportHeader,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ReportCenter.scss';
@import '@/styles/bootstrap/b-button.scss';

.container.camp-select {
  margin-top: 0;
}

.report-cards {
  & .container {
    max-width: initial;
  }
}
</style>
